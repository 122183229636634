/**
 * Film.js
 *
 * Created by Dennis Gecaj on 12/28/17
 *
 * @desc Film is a container component, displaying the film page.
 * @author Dennis Gecaj
 * @copyright © Yello Lab Inc. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';
import ReactPlayer from 'react-player';

import FullWrapper from '../../components/wrappers/FullWrapper';

import './Styles.css';
import ActionButton from "../../components/buttons/ActionButton";

class FocusbaseFilm extends Component {

  //  As the film container is static, there is no need to re-render.
  shouldComponentUpdate() {
    return false;
  }

  redirectToLanding = () => {
    this.props.history.push('/focusbase');
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <FullWrapper>
        <div className="FocusbaseFilmPage CenterWrapperTag">
          <ActionButton className="FocusbaseFilmPage ActionButton" backgroundColor={'#000000'} title="Back" action={this.redirectToLanding} />
        </div>
        <div className="FocusbaseFilmPage PlayerWrapperTag">
          <ReactPlayer
            url="https://youtu.be/O603pXX5s3M"
            playing
            controls={false}
            width="100%"
            height="100%"
            youtubeConfig={{
              controls: 0,
              modestbranding: 1,
            }}
          />
        </div>
      </FullWrapper>
    );
  }
}

export default FocusbaseFilm;
