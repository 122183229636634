/**
 * FullWrapper.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The full wrapper is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import PropTypes from 'prop-types';
import React from 'react';

import './Styles.css';

const FullWrapper = props => (
  <div className={`FullWrapperComponent ${props.className}`}>{props.children}</div>
);

FullWrapper.defaultProps = {
  className: '',
};

FullWrapper.propTypes = {
  /** Additional class name for the component. */
  className: PropTypes.string,
  /** Child elements for the full wrapper. */
  children: PropTypes.element.isRequired,
};

export default FullWrapper;
