/**
 * ColumnWrapper.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The column wrapper is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ActionButton from '../../components/buttons/ActionButton';

import './Styles.css';
import '../../styles/Fonts.css';

class AboutCard extends Component {
  render() {
    return (
      <div className={`AboutCardComponent CardTag ${this.props.className}`}>
        <span className="AboutCardComponent TitleLargeFont">
            Dennis Gecaj
        </span>
        <span className="AboutCardComponent BodyFont OrganizationTag">is a </span>
        <em className="AboutCardComponent BodyFont">full stack product designer </em>
        <span className="AboutCardComponent BodyFont OrganizationTag">from Germany working in Tech with a </span>
        <em className="AboutCardComponent BodyFont">master of science degree </em>
        <span className="AboutCardComponent BodyFont OrganizationTag">in industrial design from </span>
        <em className="AboutCardComponent BodyFont">TUDelft. </em>
        <span className="AboutCardComponent BodyFont OrganizationTag">He has first hand industry experience and is currently working at </span>
        <em className="AboutCardComponent BodyFont">Trade Republic in Berlin.</em>

        <ActionButton
          className="AboutCardComponent SubtitleSmallFont ActionTag"
          title='About'
          backgroundColor={'#000000'}
          action={() => {
            this.props.history.push('/about');
            window.scrollTo(0, 0);
          }
          }
        />
      </div>
    );
  }
}

export default withRouter(AboutCard);
