/**
 * ColumnWrapper.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The column wrapper is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';

import './Styles.css';
import '../../styles/Fonts.css';

class QuoteCard extends Component {
  render() {
    let initial = this.props.description.charAt(0);
    let description = this.props.description.substr(1);

    return (
      <div className={`QuoteCardComponent CardTag ${this.props.className}`} style={{backgroundColor: this.props.backgroundColor, color: this.props.color}}>
        <h1 className="QuoteCardComponent QuotationFont QuotationTag">
          “
        </h1>
        <span
          className={`QuoteCardComponent QuoteFont ${this.props.marker}`}>
          {this.props.quote}
        </span>
        <div className="QuoteCardComponent ContentTag">
          <span className="TitleSmallFont">
          {initial}
        </span>
          <span className="BodyFont">
          {description}
        </span>
        </div>
      </div>
    );
  }
}

export default QuoteCard;
