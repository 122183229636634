/**
 * App.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @classdesc The app is the very start of the portfolio.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Head from './components/head/Head';
import Portfolio from './containers/portfolio/Portfolio';
import About from "./containers/about/About";
import YelloCaseStudy from './containers/yello/YelloCaseStudy';
import YelloFilm from './containers/yelloFilm/YelloFilm';
import FocusbaseCaseStudy from "./containers/focusbase/FocusbaseCaseStudy";
import CoffeeCollectionCaseStudy from "./containers/coffeeCollection/CoffeeCollectionCaseStudy";

import AllWrapper from './components/wrappers/AllWrapper';
import BodyWrapper from './components/wrappers/BodyWrapper';

import './App.css';
import FocusbaseFilm from "./containers/focusbaseFilm/FocusbaseFilm";

function App() {
  return (
    <BrowserRouter>
      <AllWrapper className="GreyBackgroundColor">
        <Head />
        <BodyWrapper>
          <Switch>
            <Route exact path="/" component={Portfolio} />
            <Route exact path="/about" component={About} />
            <Route exact path="/yello" component={YelloCaseStudy} />
            <Route exact path="/yello/film" component={YelloFilm} />
            <Route exact path="/focusbase" component={FocusbaseCaseStudy} />
            <Route exact path="/focusbase/film" component={FocusbaseFilm} />
            <Route exact path="/coffeeCollection" component={CoffeeCollectionCaseStudy} />
          </Switch>
        </BodyWrapper>
      </AllWrapper>
    </BrowserRouter>
  );
}

export default App;
