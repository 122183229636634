/**
 * ColumnWrapper.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The column wrapper is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import React from 'react';
import { withRouter } from 'react-router-dom';

import ActionButton from '../../components/buttons/ActionButton';
import SocialButton from '../../components/buttons/SocialButton';

import TwitterLogo from './twitterLogo.png';
import DribbbleLogo from './dribbbleLogo.png';
import GitHubLogo from './gitHubLogo.png';

import './Styles.css';
import '../../styles/Fonts.css';

const ContactCard = (props) => {
  const redirectToTwitter = () => {
    window.open('https://twitter.com/dennisgec', '_blank');
  };
  const redirectToDribbble = () => {
    window.open('https://dribbble.com/dennisgec', '_blank');
  };
  const redirectToGitHub = () => {
    window.open('https://github.com/dennisgec', '_blank');
  };

  return (
    <div className={`ContactCardComponent CardTag ${props.className}`}>
      <ActionButton
        className="ContactCardComponent SubtitleSmallFont ActionTag"
        title='Say Hello'
        backgroundColor={'#000000'}
        action={() => {
          window.open('mailto: dennisgec@me.com', '_self');
        }
        }
      />
      <div className="ContactCardComponent SocialTag">
        <SocialButton image={TwitterLogo} action={redirectToTwitter} />
        <SocialButton image={DribbbleLogo} action={redirectToDribbble} />
        <SocialButton image={GitHubLogo} action={redirectToGitHub} />
      </div>
    </div>
  );
};

export default withRouter(ContactCard);
