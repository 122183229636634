/**
 * ColumnWrapper.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The column wrapper is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';

import './Styles.css';
import '../../styles/Fonts.css';

class MosaicImageCard extends Component {
  render() {
    return (
      <div className={`${this.props.className} MosaicImageCardComponent CardTag`}>
        <div
          className="MosaicImageCardComponent FirstImage"
          style={{backgroundImage: `url(${this.props.images[0]})`}}
        />
        <div
          className="MosaicImageCardComponent SecondImage"
          style={{backgroundImage: `url(${this.props.images[1]})`}}
        />
        <div
          className="MosaicImageCardComponent ThirdImage"
          style={{backgroundImage: `url(${this.props.images[2]})`}}
        />
        <div
          className="MosaicImageCardComponent FourthImage"
          style={{backgroundImage: `url(${this.props.images[3]})`}}
        />
      </div>
    );
  }
}

export default MosaicImageCard;
