/**
 * ColumnWrapper.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The column wrapper is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ActionButton from '../../components/buttons/ActionButton';

import './Styles.css';
import '../../styles/Fonts.css';

class ProjectCard extends Component {
  render() {
    let hashtags;
    if (this.props.hashtags) {
      hashtags = this.props.hashtags.map((hashtag, _) => {
        return (<span
          className="ProjectCardComponent HashtagFont HashTag"
          style={{backgroundColor: this.props.color + '1A', color: this.props.color}}>
        {hashtag}
      </span>)
      });
    }

    let caseStudy;
    if (this.props.caseStudy) {
      caseStudy = (<ActionButton
        className="ProjectCardComponent SubtitleSmallFont ActionTag"
        title="Case Study"
        backgroundColor={this.props.color}
        action={() => {
          this.props.history.push(this.props.caseStudy.link);
          window.scrollTo(0, 0);
        }
        }
      />)
    }

    return (
      <div className={`ProjectCardComponent CardTag ${this.props.className}`} style={{backgroundColor: this.props.backgroundColor, backgroundImage: `url(${this.props.image})`}}>
        <h1 className="ProjectCardComponent TitleSmallFont TitleTag" style={{color: this.props.color}}>{this.props.title}</h1>
        <img
          className="ProjectCardComponent LogoTag"
          src={this.props.logo}
          alt="Shows the logo of the project."
        />
        <h2 className="ProjectCardComponent SubtitleSmallFont SubtitleTag" style={{color: this.props.color}}>{this.props.subtitle}</h2>
        {hashtags}
        <p className="ProjectCardComponent BodyFont BodyTag" style={{color: this.props.color}}>
          {this.props.description}
        </p>
        <div className="ProjectCardComponent InfoTag">
          <span
            className="ProjectCardComponent SubtitleSmallFont"
            style={{color: this.props.color + '66'}}>
            Organization
          </span>
          <span
            className="ProjectCardComponent SubtitleSmallFont OrganizationTag"
            style={{color: this.props.color}}>
            {this.props.organization}
          </span>
        </div>
        <div className="ProjectCardComponent InfoTag">
          <span
            className="ProjectCardComponent SubtitleSmallFont"
            style={{color: this.props.color + '66'}}>
            Role
          </span>
          <span
            className="ProjectCardComponent SubtitleSmallFont RoleTag"
            style={{color: this.props.color}}>
            {this.props.role}
          </span>
        </div>
        <div className="ProjectCardComponent ActionsTag">
          {caseStudy}
        </div>
      </div>
    );
  }
}

export default withRouter(ProjectCard);
