/**
 * ColumnWrapper.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The column wrapper is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ActionButton from '../../components/buttons/ActionButton';

import './Styles.css';
import '../../styles/Fonts.css';

class AboutHeroCard extends Component {
  render() {
    return (
      <div className={`AboutHeroCardComponent CardTag ${this.props.className}`}>
        <h1 className="AboutHeroCardComponent TitleLargeFont">
            About
        </h1>
        <span className="AboutHeroCardComponent BodyFont OrganizationTag">Dennis is a </span>
        <em className="AboutHeroCardComponent BodyFont">Product Designer </em>
        <span className="AboutHeroCardComponent BodyFont OrganizationTag">born and raised in Germany and currently </span>
        <em className="AboutHeroCardComponent BodyFont">working at Trade Republic in Berlin. </em>
        <span className="AboutHeroCardComponent BodyFont OrganizationTag">With a passion of working within the </span>
        <em className="AboutHeroCardComponent BodyFont">intersection of Technology and Design </em>
        <span className="AboutHeroCardComponent BodyFont OrganizationTag">he not only had the privilege to work on projects for bigger companies such as Lacoste and Deutsche Bahn and directly impact the user experience of </span>
        <em className="AboutHeroCardComponent BodyFont">more than a hundred thousand people, </em>
        <span className="AboutHeroCardComponent BodyFont OrganizationTag">but also start and be part of a couple of startups. </span>

        <ActionButton
          className="AboutHeroCardComponent SubtitleSmallFont ActionTag"
          title='Back'
          backgroundColor={'#000000'}
          action={() => {
            this.props.history.push('/');
            window.scrollTo(0, 0);
          }
          }
        />
      </div>
    );
  }
}

export default withRouter(AboutHeroCard);
