/**
 * Portfolio.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc Landing is a container component, displaying the entry page.
 * @author Dennis Gecaj
 * @copyright © Yello Lab Inc. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';

import TextCard from "../../components/textCard/TextCard";
import ImageCard from "../../components/imageCard/ImageCard";

import CenterWrapper from '../../components/wrappers/CenterWrapper';
import FullWrapper from '../../components/wrappers/FullWrapper';
import ColumnWrapper from '../../components/wrappers/ColumnWrapper';

import './Styles.css';

import ProfileImage from "./profileImage.jpg";
import AboutHeroCard from "../../components/aboutHeroCard/AboutHeroCard";
import SkillsCard from "../../components/skillsCard/SkillsCard";
import DesignIcon from "./designIcon.png";
import TechnologyIcon from "./technologyIcon.png";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import TUDelftLogo from "./tuDelftLogo.png";
import TUMLogo from "./tumLogo.png";

class About extends Component {
  //  As the landing container is static, there is no need to re-render.
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <FullWrapper>
        <CenterWrapper>
          <ColumnWrapper>
            <AboutHeroCard
              className="AboutContainer IntroductionTag"/>
            <ImageCard
              className="AboutContainer ProfileImageTag"
              image={ProfileImage}/>
          </ColumnWrapper>
          <ColumnWrapper>
            <TextCard
              className="AboutContainer EducationTextTag"
              title="Education"
              description="With 18 years of age, Dennis moved away from his hometown Cologne to start his architecture studies in Munich. In 2016 he graduated from the Technical University of Munich with a 1.0 (A+ U.S equivalent).
In 2017 he moved to the Netherlands and began his studies in Product Design at the Technical University of Delft. He graduated in 2019 with a Master of Science degree with a focus on Design for Interaction. "
              backgroundColor="#F5F5F5"
              color="#000000"/>
            <ExperienceCard
              className="AboutContainer ExperienceTag"
              title="Product Design"
              subtitle={"2019 • 2 years"}
              hashtags={["Technical University Delft", "Master of Science"]}
              logo={TUDelftLogo}
              backgroundColor="#E6EFF2"
              color="#2E464D"/>
            <ExperienceCard
              className="AboutContainer ExperienceTag"
              title="Architecture"
              subtitle={"2016 • 4 years"}
              hashtags={["Technical University Munich", "Bachelor of Arts"]}
              logo={TUMLogo}
              backgroundColor="#E6ECF2"
              color="#2E3F4D"/>
          </ColumnWrapper>
          <ColumnWrapper>
            <TextCard
              className="AboutContainer SkillsTextTag"
              title="Skills"
              description="Skills are crucial, especially for the profession as a Product Designer, but now more than ever, especially within Tech, the ability to think openly and act agile in a constantly changing environment is vital. Nevertheless, here, you can find a handful of skills and tools I currently use within my design process."
              backgroundColor="#F5F5F5"
              color="#000000"/>
            <SkillsCard
              className="AboutContainer DesignSkillsCard"
              title="Design"
              icon={DesignIcon}
              hashtags={['User Research', 'Interviews', 'Flow Diagrams', 'Context Mapping', 'Usability Testing', 'Storyboards', 'UX & UI Design', 'Design Drawing', 'Illustration', 'Graphic Design', 'Mobile Design', 'Web Design', 'Branding']}
              backgroundColor="#F5F5F5"
              color="#000000"/>
            <SkillsCard
              className="AboutContainer SoftwareSkillsCard"
              title="Software"
              icon={TechnologyIcon}
              hashtags={['iOS Development', 'Swift', 'Objective-C', 'Web Development', 'JavaScript', 'Node Js', 'React']}
              backgroundColor="#F5F5F5"
              color="#000000"/>
          </ColumnWrapper>
        </CenterWrapper>
      </FullWrapper>
    );
  }
}

export default About;
