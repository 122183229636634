/**
 * Portfolio.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc Landing is a container component, displaying the entry page.
 * @author Dennis Gecaj
 * @copyright © Yello Lab Inc. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';

import AboutCard from '../../components/aboutCard/AboutCard';
import ContactCard from '../../components/contactCard/ContactCard';
import ProjectCard from '../../components/projectCard/ProjectCard';
import FooterCard from '../../components/footerCard/FooterCard';

import CenterWrapper from '../../components/wrappers/CenterWrapper';
import FullWrapper from '../../components/wrappers/FullWrapper';
import ColumnWrapper from '../../components/wrappers/ColumnWrapper';

import './Styles.css';

import projectsJson from '../../data/projects';

import FocusbaseIllustration from './focusbaseIllustration.jpg';
import FocusbaseLogo from './focusbaseLogo.png';
import YelloIllustration from './yelloIllustration.jpg';
import YelloLogo from './yelloLogo.png';
import UserlaneIllustration from './userlaneIllustration.jpg';
import UserlaneLogo from './userlaneLogo.png';
import RideSharingAppIllustration from './rideSharingAppIllustration.png';
import RideSharingLogo from './rideSharingAppLogo.png';
import AiderlyIllustration from './aiderlyIllustration.jpg';
import AiderlyLogo from './aiderlyLogo.png';
import CoffeeCollectionIllustration from './coffeeCollectionIllustration.jpg';
import CoffeeCollectionLogo from './coffeeCollectionLogo.png';
import LacosteIllustration from './lacosteIllustration.jpg';
import LacosteLogo from './lacosteLogo.png';

class Portfolio extends Component {
  //  As the landing container is static, there is no need to re-render.
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <FullWrapper>
        <CenterWrapper>
          <ColumnWrapper>
            <AboutCard/>
            <ContactCard/>
            <ProjectCard
              className="PortfolioContainer FocusbaseTag"
              title={projectsJson.focusbase.title}
              subtitle={projectsJson.focusbase.subtitle}
              hashtags={projectsJson.focusbase.hashtags}
              description={projectsJson.focusbase.description}
              image={FocusbaseIllustration}
              logo={FocusbaseLogo}
              organization={projectsJson.focusbase.organization}
              role={projectsJson.focusbase.role}
              caseStudy={projectsJson.focusbase.caseStudy}
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
            <ProjectCard
              className="PortfolioContainer YelloTag"
              title={projectsJson.yello.title}
              subtitle={projectsJson.yello.subtitle}
              hashtags={projectsJson.yello.hashtags}
              description={projectsJson.yello.description}
              image={YelloIllustration}
              logo={YelloLogo}
              organization={projectsJson.yello.organization}
              role={projectsJson.yello.role}
              caseStudy={projectsJson.yello.caseStudy}
              backgroundColor="#E6F2EE"
              color="#2E4D42"/>
            <ProjectCard
              className="PortfolioContainer RideSharingAppTag"
              title={projectsJson.rideSharingApp.title}
              subtitle={projectsJson.rideSharingApp.subtitle}
              hashtags={projectsJson.rideSharingApp.hashtags}
              description={projectsJson.rideSharingApp.description}
              image={RideSharingAppIllustration}
              logo={RideSharingLogo}
              organization={projectsJson.rideSharingApp.organization}
              role={projectsJson.rideSharingApp.role}
              backgroundColor="#F2E6E6"
              color="#4D332E"/>
            <ProjectCard
              className="PortfolioContainer UserlaneTag"
              title={projectsJson.userlane.title}
              subtitle={projectsJson.userlane.subtitle}
              hashtags={projectsJson.userlane.hashtags}
              description={projectsJson.userlane.description}
              image={UserlaneIllustration}
              logo={UserlaneLogo}
              organization={projectsJson.userlane.organization}
              role={projectsJson.userlane.role}
              backgroundColor="#E8E6F2"
              color="#332E4D"/>
            <ProjectCard
              className="PortfolioContainer AiderlyTag"
              title={projectsJson.aiderly.title}
              subtitle={projectsJson.aiderly.subtitle}
              hashtags={projectsJson.aiderly.hashtags}
              description={projectsJson.aiderly.description}
              image={AiderlyIllustration}
              logo={AiderlyLogo}
              organization={projectsJson.aiderly.organization}
              role={projectsJson.aiderly.role}
              backgroundColor="#E6F0F2"
              color="#2E484D"/>
            <ProjectCard
              className="PortfolioContainer CoffeeCollectionTag"
              title={projectsJson.coffeeCollection.title}
              subtitle={projectsJson.coffeeCollection.subtitle}
              hashtags={projectsJson.coffeeCollection.hashtags}
              description={projectsJson.coffeeCollection.description}
              image={CoffeeCollectionIllustration}
              logo={CoffeeCollectionLogo}
              organization={projectsJson.coffeeCollection.organization}
              role={projectsJson.coffeeCollection.role}
              caseStudy={projectsJson.coffeeCollection.caseStudy}
              backgroundColor="#F2EEE6"
              color="#4D432E"/>
            <ProjectCard
              className="PortfolioContainer LacosteTag"
              title={projectsJson.lacoste.title}
              subtitle={projectsJson.lacoste.subtitle}
              hashtags={projectsJson.lacoste.hashtags}
              description={projectsJson.lacoste.description}
              image={LacosteIllustration}
              logo={LacosteLogo}
              organization={projectsJson.lacoste.organization}
              role={projectsJson.lacoste.role}
              backgroundColor="#F2E8E6"
              color="#4D332E"/>
              <FooterCard/>
          </ColumnWrapper>
        </CenterWrapper>
      </FullWrapper>
    );
  }
}

export default Portfolio;
