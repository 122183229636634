/**
 * ColumnWrapper.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The column wrapper is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';

import sizeMe from 'react-sizeme';
import StackGrid from 'react-stack-grid';

import './Styles.css';

class ColumnWrapper extends Component {
  render() {
    const {
      size: {
        width
      }
    } = this.props;

    return (
      <StackGrid
        gridRef={grid => this.grid = grid}
        className={`ColumnWrapperComponent ${this.props.className}`}
        gutterWidth={width <= 420 ? 25 : 50}
        gutterHeight={width <= 420 ? 25 : 50}
        duration={0}
        columnWidth={width <= 840 ? '100%' : '50%'}
      >
        {this.props.children}
      </StackGrid>
    );
  }
}

export default sizeMe()(ColumnWrapper);
