/**
 * Portfolio.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc Landing is a container component, displaying the entry page.
 * @author Dennis Gecaj
 * @copyright © Yello Lab Inc. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';

import HeroCard from '../../components/heroCard/HeroCard';
import TextCard from "../../components/textCard/TextCard";
import ImageCard from "../../components/imageCard/ImageCard";

import CenterWrapper from '../../components/wrappers/CenterWrapper';
import FullWrapper from '../../components/wrappers/FullWrapper';
import ColumnWrapper from '../../components/wrappers/ColumnWrapper';

import './Styles.css';

import projectsJson from '../../data/projects';

import CoffeeCollectionHeroIllustration from "./coffeeCollectionHeroIllustration.jpg";
import CoffeeCollectionLogo from "../portfolio/coffeeCollectionLogo.png";
import CoffeeCollectionFrontIllustration from "./coffeeCollectionFrontIllustration.jpg";
import CoffeeCollectionSideIllustration from "./coffeeCollectionSideIllustration.jpg";
import ExplorationIllustration from "./explorationIllustration.jpg";
import BuildingCollection from "./buildingCollection.jpg";
import CncMilling from "./cncMilling.jpg";
import Assembly from "./assembly.jpg";
import Showcase from "./showcase.jpg";
import MosaicImageCard from "../../components/mosaicImageCard/MosaicImageCard";
import ExplanationCard from "../../components/explanationCard/ExplanationCard";

class CoffeeCollectionCaseStudy extends Component {
  //  As the landing container is static, there is no need to re-render.
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <FullWrapper>
        <CenterWrapper>
          <HeroCard
            className="CoffeeCollectionCaseStudyContainer HeroTag"
            title={projectsJson.coffeeCollection.title}
            subtitle={projectsJson.coffeeCollection.subtitle}
            hashtags={projectsJson.coffeeCollection.hashtags}
            description={projectsJson.coffeeCollection.description}
            image={CoffeeCollectionHeroIllustration}
            logo={CoffeeCollectionLogo}
            organization={projectsJson.coffeeCollection.organization}
            role={projectsJson.coffeeCollection.role}
            actions={projectsJson.coffeeCollection.actions}
            backgroundColor="#F2EEE6"
            color="#4D432E"/>
          <ColumnWrapper>
            <TextCard
              className="CoffeeCollectionCaseStudyContainer IntroductionTag"
              description="We are always on the move. On our stressful mornings, we frequently stop for a quick coffee and take it to go without taking the time to appreciate this morning ritual.
Coffee Collection is a coffee machine on the go. Three elements which seamlessly are held together using strong magnets, help you make your morning coffee while appreciating the ritual. The first compartment holds fresh coffee beans, while the second part features a bean grinder. The last element is both a coffee cup and a coffee filter. "
              backgroundColor="#F2EEE6"
              color="#4D432E"/>
            <ImageCard
              className="CoffeeCollectionCaseStudyContainer FrontIllustrationImageTag"
              image={CoffeeCollectionFrontIllustration}/>
            <ImageCard
              className="CoffeeCollectionCaseStudyContainer SideIllustrationImageTag"
              image={CoffeeCollectionSideIllustration}/>
          </ColumnWrapper>
          <ColumnWrapper>
            <TextCard
              className="CoffeeCollectionCaseStudyContainer ExplorationTextTag"
              marker="BrownMarkerTag"
              title="Exploration"
              description="The method of the Zen Design process approaches Product Design differently. Rather than focusing on the desired product, this method shifts focus towards the desired qualities in terms of materials and interaction.
During the exploration phase, aesthetic as well as interaction references helped form a direction for the overall project. "
              backgroundColor="#F2EEE6"
              color="#4D432E"/>
            <ImageCard
              className="CoffeeCollectionCaseStudyContainer ExplorationIllustrationImageTag"
              image={ExplorationIllustration}/>
            <ExplanationCard
              className="CoffeeCollectionCaseStudyContainer"
              explanation="Minimilastic references during early explorations."
              backgroundColor="#F2EEE6"
              color="#4D432E"/>
          </ColumnWrapper>
          <ColumnWrapper>
            <TextCard
              className="CoffeeCollectionCaseStudyContainer BuildingTextTag"
              marker="BrownMarkerTag"
              title="Building"
              description="The focus while prototyping the final design was especially put into the aesthetic value of the product. Materials, textures, and form were essential components while building Coffee Collection.
The wood elements were CNC milled and carefully stacked together to produce a seamless texture. The surface was finally treated with bee wax to create a soft finish.
The base of every element is 3D printed, and its white texture was inspired by the white milk foam we all know from our morning cappuccinos.
The interaction of taking the three elements apart and back together was incredibly valuable. The invisible use of magnets was just magical. The minute you start stacking the compartments, the existence of the magnets becomes apparent, and they fulfill their functionality. They are there when you need them, but they disappear when you don’t. "
              backgroundColor="#F2EEE6"
              color="#4D432E"/>
            <MosaicImageCard
              className="CoffeeCollectionCaseStudyContainer WorkplaceImagesTag"
              images={[BuildingCollection, CncMilling, Assembly, Showcase]}/>
            <ExplanationCard
              className="CoffeeCollectionCaseStudyContainer"
              explanation="Building process for final prototype."
              backgroundColor="#F2EEE6"
              color="#4D432E"/>
          </ColumnWrapper>
        </CenterWrapper>
      </FullWrapper>
    );
  }
}

export default CoffeeCollectionCaseStudy;
