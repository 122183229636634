/**
 * ColumnWrapper.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The column wrapper is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import React from 'react';

import './Styles.css';
import '../../styles/Fonts.css';

const ExplanationCard = (props) => {
  return (
    <div className={`ExplanationCardComponent CardTag ${props.className}`} style={{backgroundColor: props.backgroundColor, color: props.color}}>
      <div className="ExplanationCardComponent FooterTextTag">
        <span className="ExplanationCardComponent FooterFont">{props.explanation}</span>
      </div>
    </div>
  );
};

export default ExplanationCard;
