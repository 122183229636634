/**
 * ColumnWrapper.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The column wrapper is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';

import './Styles.css';
import '../../styles/Fonts.css';

class SkillsCard extends Component {
  render() {
    let hashtags;
    if (this.props.hashtags) {
      hashtags = this.props.hashtags.map((hashtag, _) => {
        return (<span
          className="SkillsCardComponent HashtagFont HashTag"
          style={{backgroundColor: this.props.color + '1A', color: this.props.color}}>
        {hashtag}
      </span>)
      });
    }

    return (
      <div className={`SkillsCardComponent CardTag ${this.props.className}`} style={{backgroundColor: this.props.backgroundColor}}>
        <h1 className="SkillsCardComponent TitleSmallFont TitleTag" style={{color: this.props.color}}>{this.props.title}</h1>
        {hashtags}
      </div>
    );
  }
}

export default SkillsCard;
