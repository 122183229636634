/**
 * Head.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The head is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import Helmet from 'react-helmet';
import React from 'react';

const Head = () => (
  <Helmet>
    <title>Dennis Gecaj</title>
    <meta charSet="utf-8" />
    <meta httpEquiv="x-ua-compatible" content="ie=edge" />
    <meta name="description" content="" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
    />
    <meta name="theme-color" content="#0CE588" />
    <link rel="stylesheet" href="https://use.typekit.net/git2dvd.css" />
  </Helmet>
);

export default Head;
