/**
 * Portfolio.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc Landing is a container component, displaying the entry page.
 * @author Dennis Gecaj
 * @copyright © Yello Lab Inc. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';

import HeroCard from '../../components/heroCard/HeroCard';

import CenterWrapper from '../../components/wrappers/CenterWrapper';
import FullWrapper from '../../components/wrappers/FullWrapper';
import ColumnWrapper from '../../components/wrappers/ColumnWrapper';

import './Styles.css';

import projectsJson from '../../data/projects';

import FocusbaseHeroIllustration from "./focusbaseHeroIllustration.jpg";
import FocusbaseLogo from "../portfolio/focusbaseLogo.png";
import TextCard from "../../components/textCard/TextCard";
import FocusbaseSideIllustration from "../focusbase/focusbaseSideIllustration.jpg";
import ScreenCard from "../../components/screenCard/ScreenCard";

import SessionsLogo from "./sessionsLogo.png";
import SessionsIllustration from "./sessionsIllustration.jpg";
import ReflectionLogo from "./reflectionLogo.png";
import ReflectionIllustration from "./reflectionIllustration.jpg";
import StatisticsLogo from "./statisticsLogo.png";
import StatisticsIllustration from "./statisticsIllustration.jpg";
import FocusGoalLogo from "./focusGoalLogo.png";
import FocusGoalIllustration from "./focusGoalllustration.jpg";
import OfficeInternIllustration from "./officeInternIllustration.jpg";
import InterviewUsersIllustration from "./interviewUsersIllustration.jpg";
import VaultIllustration from "./vaultIllustration.jpg";
import MoodDiscIllustration from "./moodDiscIllustration.jpg";
import FocuscubeIllustration from "./focuscubeIllustration.jpg";
import FocusstandIllustration from "./focusstandIllustration.jpg";
import LeopoldWorkplace from "./leopoldWorkplace.jpg";
import MarkusWorkplace from "./markusWorkplace.jpg";
import FrancineWorkplace from "./francineWorkplace.jpg";
import AnnaWorkplace from "./annaWorkplace.jpg";
import MarkusTesting from "./markusTesting.jpg";
import FocusstandSideTesting from "./focusstandSideTesting.jpg";
import LeopoldTesting from "./leopoldTesting.jpg";
import FocusstandFrontTesting from "./focusstandFrontTesting.jpg";
import FinalDesignIllustration from "./finalDesignIllustration.jpg";
import ImageCard from "../../components/imageCard/ImageCard";
import MosaicImageCard from "../../components/mosaicImageCard/MosaicImageCard";
import QuoteCard from "../../components/quoteCard/QuoteCard";
import ExplanationCard from "../../components/explanationCard/ExplanationCard";
import FocusstandBackIllustration from "./focusstandBackIllustration.jpg";
import FocusbaseBreathingIllustration from "./focusbaseBreathingIllustration.jpg";

class FocusbaseCaseStudy extends Component {
  //  As the landing container is static, there is no need to re-render.
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <FullWrapper>
        <CenterWrapper>
          <HeroCard
            className="FocusbaseCaseStudyContainer HeroTag"
            title={projectsJson.focusbase.title}
            subtitle={projectsJson.focusbase.subtitle}
            hashtags={projectsJson.focusbase.hashtags}
            description={projectsJson.focusbase.description}
            image={FocusbaseHeroIllustration}
            logo={FocusbaseLogo}
            organization={projectsJson.focusbase.organization}
            role={projectsJson.focusbase.role}
            actions={projectsJson.focusbase.actions}
            backgroundColor="#E6ECF2"
            color="#2E3E4D"/>
          <ColumnWrapper>
            <TextCard
              className="FocusbaseCaseStudyContainer IntroductionTag"
              description="How does one promote productivity for the individual when every individual has different ways of working? This project uncovers universal influences for the individual worker and tackles those using a design intervention which promotes self-reflection and creates a more distraction-free workplace."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
            <ImageCard
              className="FocusbaseCaseStudyContainer ImageTag"
              image={FocusbaseSideIllustration}/>
            <ScreenCard
              className="FocusbaseCaseStudyContainer SessionsTag"
              logo={SessionsLogo}
              title="Sessions"
              image={SessionsIllustration}
              description="See all your sessions at a glance. A calendar view on top of the screen lets you easily navigate by weeks to find the session you are looking for. Blue focus rings indicate whether you reached your goal or not. The simple visualization of half circles shows you your mood states based on energy and calm levels."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
            <ScreenCard
              className="FocusbaseCaseStudyContainer ReflectionTag"
              logo={ReflectionLogo}
              title="Reflection"
              image={ReflectionIllustration}
              description="Collect essential data after every focus session. Three sliders, energy, calm, and focus quality let you quickly reflect on your session. Familiar emoji help you understand the essential components that make up your mood."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
            <ScreenCard
              className="FocusbaseCaseStudyContainer StatisticsTag"
              logo={StatisticsLogo}
              title="Statistics"
              image={StatisticsIllustration}
              description="Using statistics, you can do more with the data you collect from your sessions. Compare average performances across weekdays and gain insights into your own way of working to get even better."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
            <ScreenCard
              className="FocusbaseCaseStudyContainer FocusGoalTag"
              logo={FocusGoalLogo}
              title="Focus Goal"
              image={FocusGoalIllustration}
              description="Focus Goals get you motivated for the day. Each morning as soon as you open the app you get to set you personal goal. You can easily see your progress througout the day and finish closing that rings."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
          </ColumnWrapper>
          <ColumnWrapper>
            <TextCard
              className="FocusbaseCaseStudyContainer FieldResearchTag"
              marker="BlueMarkerTag"
              title="Field Research"
              description="In context research was necessary to surface possible design opportunities within the scope of the workplace. The focus was especially being shifted to the worker as an individual within the working environment and the unique behavior that characterizes individual productivity.
Furthermore, the desk itself as a workplace was central to the research. Existing objects at the desk and their effect on individual productivity as well as functionality and value for employees was another critical aspect of the research."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
            <ImageCard
              className="FocusbaseCaseStudyContainer OfficeInternImageTag"
              image={OfficeInternIllustration}/>
            <TextCard
              className="FocusbaseCaseStudyContainer InterviewTextTag"
              description="The criteria for the selection of companies that would participate in the research was intentionally limited to open space environments.
Open spaces are prone to distraction and frequent communications between colleagues and employees. The already existing interruptions caused by the open office environment itself can surface more instances of so-called productive disruptions for employees and in turn, offer better insights into problem spaces for individual productivity than traditional office spaces."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
            <ImageCard
              className="FocusbaseCaseStudyContainer InterviewUsersImageTag"
              image={InterviewUsersIllustration}/>
            <ExplanationCard
              className="FocusbaseCaseStudyContainer WorkplaceExplanationTag"
              explanation="Some of the employee’s participating in the research."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
            <QuoteCard
              className="FocusbaseCaseStudyContainer QuoteTag"
              marker="BlueMarkerTag"
              quote="My phone is always near me."
              description="Initial interviews were conducted at the employee’s respective workplace, not only to gain a glimpse into their work but to also do the research directly at their individual desks and to conduct an in-depth analysis of tools and products that are already manifested at their workplace. Further points of interest for the interview were the ability of decision making throughout the day and self- organization methods."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
            <MosaicImageCard
              className="FocusbaseCaseStudyContainer MosaicTag"
              images={[LeopoldWorkplace, MarkusWorkplace, FrancineWorkplace, AnnaWorkplace]}/>
            <ExplanationCard
              className="FocusbaseCaseStudyContainer WorkplaceExplanationTag"
              explanation="A selection of the workplaces that were analyzed."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
          </ColumnWrapper>
          <ColumnWrapper>
            <TextCard
              className="FocusbaseCaseStudyContainer ConceptTextTag"
              marker="BlueMarkerTag"
              title="Concepts"
              description="Vault is a phone case designed, so you use it less. The case itself has horizontal bricks which slide in after you put your phone inside. Two small cutouts still enable basic functionality. The bottom opening allows answering phone calls and the top cutout can both display time and caller-id in case of a phone call.
The goal of the case is to make the barrier from focus to distraction as hard as possible. Once users in a working environment pick up their phone, the transition is almost seamless and goes unnoticed — designing this transition to be as noticeable as possible will, in turn, prevent pickups and improve productivity."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
            <ImageCard
              className="FocusbaseCaseStudyContainer ConceptImageTag"
              image={VaultIllustration}/>
            <ImageCard
              className="FocusbaseCaseStudyContainer ConceptImageTag"
              image={MoodDiscIllustration}/>
            <TextCard
              className="FocusbaseCaseStudyContainer ConceptTextTag"
              description="Mood Disc a device that indicates to colleagues whether an employee is currently focused or open for collaboration. The simple disc shape can be turned either way to display the open or concentrated state. A red ring light then indicates the availability of collaboration to others.
Furthermore, a small display and control interface gives the user the ability to record the current mood state. A separate phone application lets the user pre- define those moods and use them when turning the disc into focus mode."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
            <TextCard
              className="FocusbaseCaseStudyContainer ConceptTextTag"
              description="Focuscube is a simple desk clock that acts as a focus timer. Once a user decides to focus and has the intent to be productive, he places the phone on the flat lightly angled surface of the clock.
The phone represents access to distraction-free working and handing it over to Focuscube, marks the transition between barriers. This interaction also clarifies the boundary of usability.
The middle clock finger on Focuscube acts as an indicator for being in focus, continually reminding the user that focus mode is running and slightly increasing 'positive stress' levels to increase productivity. Quickly glancing at the finger allows employees to have a 'mini- break' without getting distracted. Once the phone is taken off the base, the clock stops running, making the barrier of usability even more apparent."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
            <ImageCard
              className="FocusbaseCaseStudyContainer ConceptImageTag"
              image={FocuscubeIllustration}/>
            <TextCard
              className="FocusbaseCaseStudyContainer ConceptTextTag"
              description="Focusstand focuses on the direct incorporation of the entire device into the product itself. Using the functionalities of the smartphone to benefit the product qualities further enhances usability.
The new form offers more than simply holding the phone. It resembles the characteristic of a charging stand. A single form with a horizontal slit, easily lets users slide their phones into the stand. The charging connector on the bottom of the stand ensures correct placements of the device, as well as charging capability."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
            <ImageCard
              className="FocusbaseCaseStudyContainer ConceptImageTag"
              image={FocusstandIllustration}/>
          </ColumnWrapper>
          <ColumnWrapper>
            <TextCard
              className="FocusbaseCaseStudyContainer TestingTag"
              marker="BlueMarkerTag"
              title="Testing"
              description="Although a very first working prototype had been developed, this first iteration still needed user feedback. Many open questions regarding the experience and design remained. To get closer to a testable prototype, a day of use would serve for proper sensitizing for users to perform a co-creation session.
Participants were employees of the Berlin-basedd startup Aiderly. Markus and Leopold from previous interview sessions would be testing the device on two separate days. After two days of initial testing, and an opportunity for the employees to familiarize themselves with the product, a half an hour session for product feedback, insights, and discussion took place. "
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
            <MosaicImageCard
              className="FocusbaseCaseStudyContainer MosaicTag"
              images={[MarkusTesting, FocusstandSideTesting, LeopoldTesting, FocusstandFrontTesting]}/>
            <ExplanationCard
              className="FocusbaseCaseStudyContainer WorkplaceExplanationTag"
              explanation="Usability testing of an earlier concept in context."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
          </ColumnWrapper>
          <ColumnWrapper>
            <TextCard
              className="FocusbaseCaseStudyContainer FinalTag"
              marker="BlueMarkerTag"
              title="Final Design"
              description="
Based on the feedback gained from the co- creation session, a new design cycle integrating the insights began. The first evaluation and most significant change in terms of the experience of the product were regarding the horizontal placement of the phone. The main goal of the product is to introduce a more distraction-free environment regarding inputs from the phone. A horizontal installation would allow for a physical design that could block the two most essential interaction points of the phone."
              backgroundColor="#E6ECF2"
              color="#2E3E4D"/>
            <ImageCard
              className="FocusbaseCaseStudyContainer ConceptImageTag"
              image={FinalDesignIllustration}/>
            <ImageCard
              className="FocusbaseCaseStudyContainer ConceptImageTag"
              image={FocusstandBackIllustration}/>
            <ImageCard
              className="FocusbaseCaseStudyContainer ConceptImageTag"
              image={FocusbaseBreathingIllustration}/>
          </ColumnWrapper>
        </CenterWrapper>
      </FullWrapper>
    );
  }
}

export default FocusbaseCaseStudy;
