/**
 * BodyWrapper.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The body wrapper is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import PropTypes from 'prop-types';
import React from 'react';

import './Styles.css';

const BodyWrapper = props => (
  <div className={`BodyWrapperComponent ${props.className}`}>{props.children}</div>
);

BodyWrapper.defaultProps = {
  className: '',
};

BodyWrapper.propTypes = {
  /** Additional class name for the component. */
  className: PropTypes.string,
  /** Child elements for the body wrapper. */
  children: PropTypes.element.isRequired,
};

export default BodyWrapper;
