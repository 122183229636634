/**
 * ActionButton.js
 *
 * Created by Dennis Gecaj on 12/28/17
 *
 * @desc The action button is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Yello Lab Inc. All rights reserved.
 * @version 1.0
 */

import PropTypes from 'prop-types';
import React from 'react';

import './Styles.css';

const ActionButton = (props) => {
  /**
   * The on click function for the action button based on props.
   *
   * @return {func} - A function for the action on click listener.
   */
  const onClick = () => {
    if (props.isLoading || props.error || !props.isEnabled) {
      return Function.prototype;
    }
    return props.action;
  };

  return (
    <div className={`ActionButtonComponent WrapperTag ${props.className}`}>
      <button
        className="ActionButtonComponent ButtonTag ActionFont"
        onClick={onClick()}
        type="button"
        style={{backgroundColor: props.backgroundColor}}
      >
        {props.title}
      </button>
    </div>
  );
};

ActionButton.defaultProps = {
  action: Function.prototype,
  className: '',
  isEnabled: true,
  isLoading: false,
  error: false,
  image: null,
};

ActionButton.propTypes = {
  /** The action when clicking on the button. */
  action: PropTypes.func,
  /** Additional class name for the component. */
  className: PropTypes.string,
  /** A boolean indicating whether the action button is enabled. */
  isEnabled: PropTypes.bool,
  /** A boolean indicating whether the action button is isLoading. */
  isLoading: PropTypes.bool,
  /** A boolean indicating whether an error occurred and the button should display it.  */
  error: PropTypes.bool,
  /** The title of the button. */
  title: PropTypes.string.isRequired,
  /** An image for the social button (e.g. twitter). */
  image: PropTypes.node,
};

export default ActionButton;
