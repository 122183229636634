/**
 * ColumnWrapper.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The column wrapper is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';

import './Styles.css';
import '../../styles/Fonts.css';

class ImageCard extends Component {
  render() {
    return (
      <div className={`${this.props.className}`}>
        <div
          className="ImageCardComponent ImageTag"
          style={{backgroundImage: `url(${this.props.image})`}}
        />
      </div>
    );
  }
}

export default ImageCard;
