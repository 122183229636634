/**
 * ColumnWrapper.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The column wrapper is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import React from 'react';
import { withRouter } from 'react-router-dom';

import LoveLogo from './loveLogo.png';

import './Styles.css';
import '../../styles/Fonts.css';

const FooterCard = (props) => {
  return (
    <div className={`FooterCardComponent CardTag ${props.className}`}>
      <div className="FooterCardComponent FooterTextTag">
        <div>
          <span className="FooterCardComponent BodyFont">Portfolio made with</span>
          <span className="FooterCardComponent BodyFont">
          <img
            className="FooterCardComponent LogoTag"
            src={LoveLogo}
            alt="Shows the logo of the project."
          />
        </span>
          <span className="FooterCardComponent BodyFont">in New York.</span>
        </div>
        <div className="FooterCardComponent CopyrightTag">
          <span className="FooterCardComponent FooterFont">Copyright © 2019 Dennis Gecaj. All rights reserved.</span>
        </div>
      </div>
    </div>
  );
};

export default withRouter(FooterCard);
