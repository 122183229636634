import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import * as firebase from "firebase/app";
import "firebase/analytics"

const firebaseConfig = {
  apiKey: "AIzaSyCfHFRBrdl3eYZzG-3-3OyTXpE5b1kWSGc",
  authDomain: "dennisgecportfolio.firebaseapp.com",
  databaseURL: "https://dennisgecportfolio.firebaseio.com",
  projectId: "dennisgecportfolio",
  storageBucket: "dennisgecportfolio.appspot.com",
  messagingSenderId: "735624816947",
  appId: "1:735624816947:web:454bd22b023472104d3646",
  measurementId: "G-6EJ97XEWZD"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
