/**
 * Portfolio.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc Landing is a container component, displaying the entry page.
 * @author Dennis Gecaj
 * @copyright © Yello Lab Inc. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';

import HeroCard from '../../components/heroCard/HeroCard';
import TextCard from "../../components/textCard/TextCard";
import ScreenCard from "../../components/screenCard/ScreenCard";

import CenterWrapper from '../../components/wrappers/CenterWrapper';
import FullWrapper from '../../components/wrappers/FullWrapper';
import ColumnWrapper from '../../components/wrappers/ColumnWrapper';

import './Styles.css';

import projectsJson from '../../data/projects';

import YelloHeroIllustration from "./yelloHeroIllustration.jpg";
import YelloLogo from "../portfolio/yelloLogo.png";
import FriendsLogo from "./friendsLogo.png";
import FriendsIllustration from "./friendsIllustration.jpg";
import PhotoAlbumLogo from "./photoAlbumLogo.png";
import PhotoAlbumsIllustration from "./photoAlbumsIllustration.jpg";
import ProfileLogo from "./profileLogo.png";
import ProfileIllustration from "./profileIllustration.jpg";
import StoryboardIllustration from "./storyboardIllustration.jpg";
import ReactionIllustration from "./reactionIllustration.jpg";
import ImageCard from "../../components/imageCard/ImageCard";
import XcodeIllustration from "./xcodeIllustration.jpg";
import IconSetIllustration from "./iconSetIllustration.jpg";
import AppStoreIllustration from "./appStoreIllustration.jpg";
import ExplanationCard from "../../components/explanationCard/ExplanationCard";


class YelloCaseStudy extends Component {
  //  As the landing container is static, there is no need to re-render.
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <FullWrapper>
        <CenterWrapper>
            <HeroCard
              className="YelloCaseStudyContainer HeroTag"
              title={projectsJson.yello.title}
              subtitle={projectsJson.yello.subtitle}
              hashtags={projectsJson.yello.hashtags}
              description={projectsJson.yello.description}
              image={YelloHeroIllustration}
              logo={YelloLogo}
              organization={projectsJson.yello.organization}
              role={projectsJson.yello.role}
              actions={projectsJson.yello.actions}
              backgroundColor="#E6F2EE"
              color="#2E4D42"/>
          <ColumnWrapper>
            <TextCard
              className="YelloCaseStudyContainer IntroductionTag"
              description="Yello introduces a new private space for your family and friends. Your social profile on Yello is protected with your phone number, so only people with access to it can send you invites and get to be your friend. Additionally, we are introducing a new story format on Yello. Stories on traditional social media are image-based and give you a limited space to create and share. Yello gives you a Lego-like experience, where you can use different widgets and components to layout and build your story without forcing you into a predefined format."
              backgroundColor="#E6F2EE"
              color="#2E4D42"/>
            <ScreenCard
              className="YelloCaseStudyContainer FriendsTag"
              logo={FriendsLogo}
              title="Friends"
              image={FriendsIllustration}
              description="All your Friends are front and center on Yello. Using your address book you get to keep in touch with people you actually now. Like in real life. This also means that your own profile is private and only friends with your phone number get to send you an invite. So no more awkward friend requests."
              backgroundColor="#E6F2EE"
              color="#2E4D42"/>
            <ScreenCard
              className="YelloCaseStudyContainer PhotoAlbumTag"
              logo={PhotoAlbumLogo}
              title="Photo Albums"
              image={PhotoAlbumsIllustration}
              description="Photo Albums on Yello are a familiar, yet innovate new format to share your experiences with family and friends. We are introducing a new level of customization, by letting you not only share one image but create an entire story using stickers, location, text snippets and more. We simply call these elements paragraphs. You can use up to 12 paragraphs to create your own personal story and completely customize each one of them using different colors and sizes to create your own masterpiece."
              backgroundColor="#E6F2EE"
              color="#2E4D42"/>
            <ScreenCard
              className="YelloCaseStudyContainer ProfileTag"
              logo={ProfileLogo}
              title="Profile"
              image={ProfileIllustration}
              description="Photo Albums on Yello are only visible to the friends you share them with. You are in control over who gets to see what. With Groups, it’s even easier to share only with the people you care about most."
              backgroundColor="#E6F2EE"
              color="#2E4D42"/>
            <ScreenCard
              className="YelloCaseStudyContainer StoryboardTag"
              image={StoryboardIllustration}
              description="The Storyboard on Yello has all the tools you need to create incredibly personal stories to share with your friends. Whether you simply want to snap a picture or just share an emoji, Storyboard lets you do all of that with a simple tap."
              backgroundColor="#E6F2EE"
              color="#2E4D42"/>
            <ScreenCard
              className="YelloCaseStudyContainer ReactionTag"
              image={ReactionIllustration}
              description="On Yello you don’t just get to ‘like’ something, you react to it. Using more than 200 emoji you get to express yourself in a more personal way."
              backgroundColor="#E6F2EE"
              color="#2E4D42"/>
          </ColumnWrapper>
          <ColumnWrapper>
            <TextCard
              className="YelloCaseStudyContainer ConceptTextTag"
              marker="GreenMarkerTag"
              title="Software Development"
              description="Starting with sketches and wireframes is a great way to kick off initial ideas during early concept phases, but wearing both hats as a software engineer as well as a product designer provided the opportunity to go beyond the design handoff. Many ideas that look great in flow-diagrams and UI sketches don’t work when it comes to implementation. The need for product designer beyond the handoff is vital to the success of the product. While implementing Yello, the ability to directly tweak screen transitions and animations just made the end product better."
              backgroundColor="#E6F2EE"
              color="#2E4D42"/>
            <ImageCard
              className="YelloCaseStudyContainer ConceptImageTag"
              image={XcodeIllustration}/>
            <ExplanationCard
              className="CoffeeCollectionCaseStudyContainer"
              explanation="Xcode running the Yello app."
              backgroundColor="#F2F2F2"
              color="#00000"/>
          </ColumnWrapper>
          <ColumnWrapper>
            <TextCard
              className="YelloCaseStudyContainer IconographyTextTag"
              marker="GreenMarkerTag"
              title="Iconography"
              description="Typography and iconography are the heart and soul of every interface. Working together in harmony, they both communicate functionality and aesthetics to the end-user. For Yello, a custom icon set of more than fifty icons was developed to provide a unified and consistent user interface."
              backgroundColor="#E6F2EE"
              color="#2E4D42"/>
            <ImageCard
              className="YelloCaseStudyContainer ConceptImageTag"
              image={IconSetIllustration}/>
          </ColumnWrapper>
          <ColumnWrapper>
            <TextCard
              className="YelloCaseStudyContainer LaunchTextTag"
              marker="GreenMarkerTag"
              title="Launch"
              description="After more than three months of user-testing, Yello was ready for prime time. In March of 2019, the app was pre-announced on product hunt and got more than one hundred upvotes from the community. On April 24th, the app hit the App Store worldwide. "
              backgroundColor="#E6F2EE"
              color="#2E4D42"/>
            <ImageCard
              className="YelloCaseStudyContainer ConceptImageTag"
              image={AppStoreIllustration}/>
          </ColumnWrapper>
        </CenterWrapper>
      </FullWrapper>
    );
  }
}

export default YelloCaseStudy;
