/**
 * ColumnWrapper.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The column wrapper is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';

import './Styles.css';
import '../../styles/Fonts.css';

class ScreenCard extends Component {
  render() {
    let logo;
    if (this.props.logo) {
      logo = (<img
        className="ScreenCardComponent LogoTag"
        src={this.props.logo}
        alt="Shows the logo of the project."
      />)
    }

    let title;
    if (this.props.title) {
      title = (<h2
        className="ScreenCardComponent TitleSmallFont TitleTag">
        {this.props.title}
      </h2>)
    }

    let image;
    if (this.props.image) {
      image = (<div
        className="ScreenCardComponent ImageTag"
        style={{backgroundImage: `url(${this.props.image})`}}
      />)
    }

    let initial;
    let description;
    if (this.props.description) {
      initial = (<span className="TitleSmallFont">
          {this.props.description.charAt(0)}
        </span>);
      description = (<span className="BodyFont">
          {this.props.description.substr(1)}
        </span>)
    }

    return (
      <div className={`ScreenCardComponent CardTag ${this.props.className}`} style={{backgroundColor: this.props.backgroundColor, color: this.props.color}}>
        {logo}
        {title}
        {image}
        <div className="ScreenCardComponent ContentTag">
          {initial}
          {description}
        </div>
      </div>
    );
  }
}

export default ScreenCard;
