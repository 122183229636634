/**
 * ColumnWrapper.js
 *
 * Created by Dennis Gecaj on 9/20/19
 *
 * @desc The column wrapper is a presentational component.
 * @author Dennis Gecaj
 * @copyright © Dennis Gecaj. All rights reserved.
 * @version 1.0
 */

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ActionButton from '../../components/buttons/ActionButton';

import './Styles.css';
import '../../styles/Fonts.css';

class HeroCard extends Component {
  render() {
    let hashtags;
    if (this.props.hashtags) {
      hashtags = this.props.hashtags.map((hashtag, _) => {
        return (<span
          className="HeroCardComponent HashtagFont HashTag"
          style={{backgroundColor: this.props.color + '1A', color: this.props.color}}>
        {hashtag}
      </span>)
      });
    }

    let actions;
    if (this.props.actions) {
      actions = this.props.actions.map((action, _) => {
        return (<ActionButton
          className="HeroCardComponent SubtitleSmallFont ActionTag"
          title={action.title}
          backgroundColor={this.props.color}
          action={() => {
            if (action.link) {
              this.props.history.push(action.link);
              window.scrollTo(0, 0);
            } else if (action.url) {
              window.open(action.url, '_blank');
            }
          }
          }
        />);
      })
    }

    return (
      <div className={`HeroCardComponent CardTag ${this.props.className}`} style={{backgroundColor: this.props.backgroundColor, backgroundImage: `url(${this.props.image})`}}>
        <div className="HeroCardComponent DetailsTag">
          <h1 className="HeroCardComponent TitleLargeFont TitleTag" style={{color: this.props.color}}>{this.props.title}</h1>
          <img
            className="HeroCardComponent LogoTag"
            src={this.props.logo}
            alt="Shows the logo of the project."
          />
          <h2 className="HeroCardComponent SubtitleSmallFont SubtitleTag" style={{color: this.props.color}}>{this.props.subtitle}</h2>
          {hashtags}
          <p className="HeroCardComponent BodyFont BodyTag" style={{color: this.props.color}}>
            {this.props.description}
          </p>
          <div className="HeroCardComponent InfoTag">
          <span
            className="HeroCardComponent SubtitleSmallFont"
            style={{color: this.props.color + '66'}}>
            Organization
          </span>
            <span
              className="HeroCardComponent SubtitleSmallFont OrganizationTag"
              style={{color: this.props.color}}>
            {this.props.organization}
          </span>
          </div>
          <div className="HeroCardComponent InfoTag">
          <span
            className="HeroCardComponent SubtitleSmallFont"
            style={{color: this.props.color + '66'}}>
            Role
          </span>
            <span
              className="HeroCardComponent SubtitleSmallFont RoleTag"
              style={{color: this.props.color}}>
            {this.props.role}
          </span>
          </div>
          <div className="HeroCardComponent ActionsTag">
            <ActionButton
              className="ProjectCardComponent SubtitleSmallFont ActionTag"
              title="Back"
              backgroundColor={this.props.color}
              action={() => {
                this.props.history.push("/");
                window.scrollTo(0, 0);
              }
              }
            />
            {actions}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(HeroCard);
